import React, { useState, useEffect } from "react";
import Layout from "../components/layout/layout";
import "leaflet/dist/leaflet.css";
import phone from "../resources/assets/phone.svg";
import email from "../resources/assets/email.svg";
import marker from "../resources/assets/location-pin.png";

// markup
const Contacts = () => {
  const [mapComponent, setMapComponent] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const L = require("leaflet");

      const iconAS = new L.Icon({
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: null,
        popupAnchor: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(60, 75),
        className: "leaflet-div-icon",
      });

      const { MapContainer, Marker, TileLayer } = require("react-leaflet");
      const center = [47.37756, 8.55122];
      setMapComponent(
        <MapContainer
          center={center}
          zoom={15}
          className="h-48 sm:h-96"
          zoomControl={false}
          dragging={true}
          scrollWheelZoom={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={center} icon={iconAS} />
        </MapContainer>
      );
    }
  }, []);

  return (
    <Layout>
      <div className="py-6 md:py-12 as-cont">
        <h1 className="as-h1">Contacts</h1>
        <div className="my-4 md:my-8 rounded-xl shadow-lg bg-slate-200 overflow-hidden">
          {mapComponent}
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-8 mt-10">
          <div>
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              Get in touch
            </h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                We welcome you to our website, and are happy to accommodate any
                correspondence to discuss novel research ideas and support
                applications for new team members.
              </p>
            </div>
            <div className="lg:flex lg:gap-6">
              <a
                href="mailto:astranlab@gmail.com"
                title="Click to write us an email"
                className="inline-flex mt-4 gap-2 py-1.5 px-3.5 bg-slate-100 rounded-full shadow-md items-center hover:-translate-y-1 transition-transform"
              >
                <img src={email} alt="email contact information" />
                astranlab@gmail.com
              </a>
              <a
                href="https://forms.gle/V44o4hEVDFfr7YYE9"
                target="_blank"
                rel="noopener"
                title="Fill the form"
                className="inline-flex mt-4 gap-2 py-1.5 px-3.5 text-slate-100 bg-gray-900 font-bold rounded-full shadow-md items-center hover:-translate-y-1 transition-transform"
              >
                Apply to work with us
              </a>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              Locations
            </h2>
            <div className="flex flex-col gap-6 mt-8">
              <div className="border-l-4 pl-4 border-gray-200 rounded-sm">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  ASTRAN Lab
                </h3>
                <div className="mt-1 text-base text-gray-500">
                  <p>Department of Neurosurgery</p>
                  <p className="mt-0.5">University Hospital Zurich</p>
                  <p className="mt-0.5">Office STE G2, Sternwartstrasse 6</p>
                  <p className="mt-0.5">8091 Zurich, Switzerland</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contacts;
